<template>
  <div>
    <v-layout class="pd-top-12" align-center v-if="editable">
      <v-icon @click.native="addItem" style="padding: 0px">add</v-icon>
      <div class="text-xs-left v-label pd-left-8">{{ label }}:</div>
    </v-layout>
    <div class="text-xs-left v-label pd-top-12" v-if="!editable">{{ label }}:</div>

    <div v-if="errors && errors.length" class="error-msg text-sm-left v-messages pd-b-8">
      {{ errors.length === 1 ? errors[0] : errors.join(', ') }}
    </div>

    <draggable
      style="padding: 0px; width: 100%"
      v-model="bufData"
      :group="label"
      @start="drag = true"
      @end="drag = false">
      <v-layout v-for="(item, index) in bufData" :key="index" align-center>
        <v-icon>drag_indicator</v-icon>
        <component
          class="pd-left-32"
          :is="fieldsType"
          :value="item"
          @input="onEdit(index, $event)"
          :label="`${label}[${index}]`"
          :rules="rules"
          :item="item"
          @valid="isValid => validate(index, isValid)"
          :customProps="customProps"></component>
        <v-icon class="pd-left-32" @click.native="removeItem(index)" v-if="editable">delete</v-icon>
      </v-layout>
    </draggable>
  </div>
</template>

<script>
  import draggable from 'vuedraggable';

  import FormTextarea from '../FormTextarea/FormTextarea.vue';
  import FormString from '../FormString/FormString.vue';
  import FormNumber from '../FormNumber/FormNumber.vue';
  import FormInteger from '../FormInteger/FormInteger.vue';
  import FormBoolean from '../FormBoolean/FormBoolean.vue';
  import FormCode from '../FormCode/FormCode.vue';

  export default {
    name: 'form-array',
    // props: ['value', 'label', 'rules', 'required', 'customProps'],
    props: {
      value: Array,
      label: String,
      rules: Array,
      required: { type: Boolean, default: false },
      customProps: Object,
      bindedErrorMessages: {
        type: Array,
        default: () => [],
      },
      uniqKey: String,
    },

    data() {
      return {
        bufData: [],
        fieldsType: 'form-string',
        validationField: {},
        editable: true,
        validFields: [],

        errors: [],
      };
    },
    created() {
      this.bufData = this.value || [];

      if (this.customProps && this.customProps.fieldsType)
        this.fieldsType = 'form-' + this.customProps.fieldsType.toLowerCase();
      if (this.customProps && this.customProps.length) {
        this.editable = false;
        for (let i = 0; i < this.customProps.length; i++) {
          this.bufData[i] = this.value && this.value[i] ? this.value[i] : null;
        }
      }

      this.bufData.forEach((data, i) => {
        this.validFields[i] = this.rules.reduce((acc, current) => {
          let result = current(data);
          if (result !== true) result = false;
          return acc && result;
        }, true);
      });
    },
    methods: {
      async validate(index, isValid) {
        this.validFields[index] = isValid;
        let isValidArray;
        this.$emit('binded-validation', { value: this.value, uniqKey: this.uniqKey });
        await Promise.resolve();

        isValidArray = this.errors.length === 0 && this.validFields.every(field => field);
        this.$emit('valid', isValidArray);
      },

      addItem() {
        this.bufData.push('');
      },
      removeItem(index) {
        this.bufData.splice(index, 1);
        this.validFields.splice(index, 1);
        this.$emit('input', this.bufData);
      },

      onEdit(index, value) {
        this.bufData[index] = value;
        this.$emit('input', this.bufData);
      },
    },
    watch: {
      bindedErrorMessages(errorMessages, oldErrorMessages) {
        this.errors = this.errors.filter(
          error => !oldErrorMessages.find(oldErr => oldErr === error)
        );
        this.errors.push(...errorMessages);
      },
    },
    components: {
      'form-textarea': FormTextarea,
      'form-string': FormString,
      'form-number': FormNumber,
      'form-integer': FormInteger,
      'form-boolean': FormBoolean,
      'form-code': FormCode,

      draggable,
    },
  };
</script>
<style scoped>
  .theme-light {
    color: rgba(0, 0, 0, 0.54);
  }

  .error-msg {
    color: #ff5252 !important;
    caret-color: #ff5252 !important;
  }

  .pd-top-12 {
    padding-top: 12px;
  }

  .pd-left-32 {
    padding-left: 32px;
  }

  .pd-left-8 {
    padding-left: 8px;
  }
</style>
